import React from 'react';
import { cn } from '@/utils/utils';
import { SubscriptionDTOV2 } from '@/utils/api/services/openapi';
import { Typography } from '@/components/Typography';
import { ActionRows, ArrayOneOrMore, RowType } from '@/components/ActionRows';

interface Props {
  rows: ArrayOneOrMore<RowType>;
  header: React.ReactNode;
  body: React.ReactNode;
}

const YellowActionBox: React.FC<Props> = ({ rows, header, body }) => (
  <div className={cn('flex', 'flex-col', 'gap-1')}>
    <div className={cn('bg-yellow-50', 'p-6', 'rounded-2xl', 'min-w-[249px]', 'md:flex-1', 'flex', 'flex-col')}>
      <Typography component="h2" type="XS-H" className={cn('mb-4')}>
        {header}
      </Typography>
      <Typography component="p" type="S-B" className={'mb-10'}>
        {body}
      </Typography>
      <ActionRows rows={rows} />
    </div>
  </div>
);

export { YellowActionBox };
