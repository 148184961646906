import { LegacyButton, LegacyButtonVariant } from '@/components/LegacyButton';
import { LegacyInput } from '@/components/LegacyInput';
import { cn } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from '@/utils/providers/AuthProvider';
import { getLayout } from '@/layouts/GuestLayout';
import { Typography } from '@/components/Typography';
import Link from 'next/link';
import React, { useState } from 'react';
import { Alert, AlertType } from '@/components/Alert';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Namespace, getTranslationProps } from '@/utils/i18n';
import isEmail from 'isemail';
import { YellowActionBox } from '@/components/YellowActionBox';
import { useLogger } from '@/utils/logger';

interface Schema {
  username: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const logger = useLogger();
  const email = router.query['email'];
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const formMethods = useForm<Schema>({
    resolver: yupResolver(
      yup.object().shape({
        username: yup
          .string()
          .required(t('guest:forgotPassword.form.errors.emptyEmail'))
          .test('email', t('guest:forgotPassword.form.errors.invalidEmail'), (value?: string) => {
            return isEmail.validate(value ?? '', { minDomainAtoms: 2 });
          }),
        password: yup.string().required(t('guest:forgotPassword.form.errors.emptyPassword'))
      })
    ),
    defaultValues: {
      username: typeof email === 'string' ? email : undefined
    },
    mode: 'onBlur'
  });

  const onSubmit = formMethods.handleSubmit(async data => {
    setIsLoading(true);
    try {
      logger.info('user is trying to log in');
      await login(data);
      logger.info('user logged in');
    } catch (e) {
      const error = getError(e);
      if (error) {
        setError(error);
      }
      logger
        .addContext({
          error: error
        })
        .info('user failed to log in');
    }
    setIsLoading(false);
  });

  const getError = (error: unknown): string | null => {
    if (!isAxiosError(error)) {
      return t('guest:login.errors.generic');
    }

    switch (error.response?.status) {
      case 401:
        return t('guest:login.errors.401');
      case 429:
        return t('guest:login.errors.429');
      default:
        return t('guest:login.errors.generic');
    }
  };

  return (
    <div className={cn('w-full', 'max-w-md', 'flex', 'flex-col', 'justify-start')}>
      <Typography type="L-H" component="h1" className={cn('mb-10', 'md:text-center')}>
        {t('guest:login.header')}
      </Typography>
      <FormProvider {...formMethods}>
        <form className={cn('flex', 'flex-col')} onSubmit={onSubmit} noValidate>
          <div className={cn('flex', 'flex-col', 'gap-8', 'md:gap-6')}>
            <LegacyInput
              id="username-input"
              type="email"
              name="username"
              autocomplete="username"
              helperText={formMethods.formState.errors.username?.message}
              fullWidth
              label={t('guest:login.form.inputs.username.label')}
            />
            <LegacyInput
              id="password-input"
              type="password"
              name="password"
              autocomplete="current-password"
              helperText={formMethods.formState.errors.password?.message}
              fullWidth
              label={t('guest:login.form.inputs.password.label')}
            />
          </div>
          {error && <Alert type={AlertType.Alert} body={error} className={cn('mt-8', 'md:mt-6')} />}
          <div
            className={cn(
              'flex',
              'md:flex-col',
              'justify-center',
              'items-center',
              'mt-16',
              'md:mt-10',
              'mb-20',
              'gap-4'
            )}
          >
            <LegacyButton
              id="analytics-login"
              buttonVariant={LegacyButtonVariant.Contained}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              className={cn('w-full', 'md:min-w-full')}
            >
              {t('guest:login.form.buttons.login')}
            </LegacyButton>
            <Typography
              component={Link}
              type="M-B"
              weight="light"
              href="/auth/forgot-password"
              className={cn('text-center', 'underline', 'w-[200px]', 'mx-4')}
            >
              {t('guest:login.forgotPassword')}
            </Typography>
          </div>
          <YellowActionBox
            rows={[
              {
                text: t('guest:login.order.orderLink.label'),
                href: t('guest:login.order.orderLink.url'),
                rowColor: 'bg-yellow-100'
              },
              {
                text: t('guest:login.order.inspectionLink.label'),
                href: t('guest:login.order.inspectionLink.url'),
                rowColor: 'bg-common-white'
              }
            ]}
            header={t('guest:login.order.header')}
            body={<span className={cn('whitespace-pre-line')}>{t('guest:login.order.body')}</span>}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export const getServerSideProps = getTranslationProps([Namespace.Global, Namespace.Guest]);

LoginPage.getLayout = getLayout;

export default LoginPage;
